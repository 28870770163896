import data from './data';
import Scrollspy from 'react-scrollspy';
import Nav from './Nav';
import './floating-nav.css';
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const FloatingNav = () => {

  const handleNavClick = (title) => {
    trackCustomEvent('Floating Navigation', 'Click', title);
  };

  return (
    <ul id="floating__nav">
      <Scrollspy 
        offset={-500} 
        className='scrollspy' 
        items={['header', 'about', 'services', 'studycases', 'testimonials', 'portfolio', 'contact']} 
        currentClassName="active"
      >
        {
          data.map(item => (
            <Nav 
              key={item.id} 
              item={item} 
              onClick={() => handleNavClick(item.title)}  // Track navigation clicks
            />
          ))
        }
      </Scrollspy>
    </ul>
  );
}

export default FloatingNav;
