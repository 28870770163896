import contacts from './data';
import './contact.css';
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const Contact = () => {

  const handleContactClick = (platform) => {
    trackCustomEvent('Contact Link', 'Click', platform);
  };

  return (
    <section id="contact">
      <h2>Get In Touch</h2>
      <p>
        Shoot us a message via any of the links below!
      </p>
      <div className="container contact__container" data-aos="fade-up">
        {
          contacts.map(contact => (
            <a 
              key={contact.id} 
              href={contact.link} 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleContactClick(contact.platform)}
            >
              {contact.icon}
            </a>
          ))
        }
      </div>
    </section>
  )
}

export default Contact;
