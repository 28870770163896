import { useEffect } from 'react'; 
import { trackCustomEvent } from '../../analytics'; // Import the tracking function
import Project from "./Project";

const Projects = ({ projects }) => {

  useEffect(() => {
    trackCustomEvent('Projects Section', 'Load', 'Projects Loaded');
  }, []);

  return (
    <div className="portfolio__projects">
      {projects.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </div>
  );
};

export default Projects;

