import { trackCustomEvent } from '../../analytics'; // Import the tracking function
import Card from "../../components/Card";

const Project = ({ project }) => {

  const handleButtonClick = (label) => {
    trackCustomEvent('Project Link', 'Click', `${project.title} - ${label}`);
  };

  return (
    <Card className="portfolio__project">
      <div className="portfolio__project-image">
        <img src={project.image} alt={project.title} />
      </div>
      <h4>{project.title}</h4>
      <p>{project.desc}</p>
      <div className="portfolio__project-cta">
        {project.private ? (
          <p className="private-notice">This project is private and cannot be shared publicly.</p>
        ) : (
          <>
            <a
              href={project.demo}
              className="btn sm"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleButtonClick('Demo')}
            >
              Demo
            </a>
            <a
              href={project.github}
              className="btn sm primary"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleButtonClick('GitHub')}
            >
              GitHub
            </a>
          </>
        )}
      </div>
    </Card>
  );
};

export default Project;

