import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineX } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { PiGithubLogoFill } from "react-icons/pi";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { GoMail } from "react-icons/go";

export const links = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About us" },
  { id: 3, link: "#services", title: "Our Services" },
  { id: 4, link: "#studycases", title: "Study Cases" },
  { id: 5, link: "#testimonials", title: "Testimonials" },
  { id: 6, link: "#portfolio", title: "Portfolio" },
  { id: 7, link: "#contact", title: "Contact us" },
];

export const socials = [
  {
    id: 1,
    link: "https://www.linkedin.com/company/ubinovalabs/",
    icon: <AiFillLinkedin />,
  },
  { id: 2, link: "https://x.com/ubinovalabs", icon: <AiOutlineX /> },
  { id: 3, link: "https://youtube.com", icon: <AiFillYoutube /> },
  {
    id: 4,
    link: "https://www.facebook.com/profile.php?id=61561096243986",
    icon: <AiOutlineFacebook />,
  },
  { id: 5, link: "https://github.com/ubinovalabs", icon: <PiGithubLogoFill /> },
  {
    id: 6,
    link: "https://www.instagram.com/ubinovalabs/",
    icon: <AiOutlineInstagram />,
  },
  {
    id: 7,
    link: "https://wa.me/+16077596219",
    icon: <FaWhatsapp />,
  },
  {
    id: 8,
    link: "mailto:ubinovalabs@gmail.com",
    icon: <SiGmail />,
  },
  {
    id: 9,
    link: "mailto:info@ubinovalabs.com",
    icon: <GoMail />,
  },
];
