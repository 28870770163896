import React, { useEffect } from "react";
import { trackCustomEvent } from '../../analytics'; // Import the tracking function
import "./modal.css";

const Modal = ({ show, onClose, content }) => {
  useEffect(() => {
    if (show) {
      trackCustomEvent('Modal', 'Open', 'Modal Opened'); // Track modal open event
    }
  }, [show]);

  const handleClose = () => {
    trackCustomEvent('Modal', 'Close', 'Modal Closed'); // Track modal close event
    onClose();
  };

  if (!show) return null;

  return (
    <div className={`modal-overlay ${show ? "show" : ""}`} onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        {content}
      </div>
    </div>
  );
};

export default Modal;

