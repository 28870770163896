import { FaHome } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import { BiWrench } from "react-icons/bi";
import { MdSchool } from "react-icons/md";
import { PiUserSoundFill } from "react-icons/pi";
import { FaBriefcase } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";

const data = [
  { id: 1, link: "#", icon: <FaHome /> },
  { id: 2, link: "#about", icon: <MdInfoOutline /> },
  { id: 3, link: "#services", icon: <BiWrench /> },
  { id: 4, link: "#studycases", icon: <MdSchool /> },
  { id: 5, link: "#testimonials", icon: <PiUserSoundFill /> },
  { id: 6, link: "#portfolio", icon: <FaBriefcase /> },
  { id: 7, link: "#contact", icon: <MdContactMail /> },
];

export default data;
