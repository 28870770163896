import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.jpg'
import Avatar3 from '../../assets/avatar3.jpg'
import Avatar4 from '../../assets/avatar4.jpg'
import Avatar5 from '../../assets/avatar5.png'


const data = [
  {
    id: 1,
    quote:
      "Ubinovalabs helped improve my business using AI, transforming Reverde into a more efficient and customer-focused eco-friendly store.",
    avatar: Avatar1,
    name: "Ma Stefanny",
    profession: "CEO, Reverde",
  },
  {
    id: 2,
    quote:
      "Ubinovalabs introduced AI into our business, making Margaritas Mexican Restaurant more productive and increasing our ROI.",
    avatar: Avatar2,
    name: "Alirio Bazan",
    profession: "CEO, Margaritas Mexican Restaurant",
  },
  {
    id: 3,
    quote:
      "Ubinovalabs helped us introduce new tools to our projects, bringing more ideas and efficiency.",
    avatar: Avatar3,
    name: "Jose Aguilar",
    profession: "CEO, EnergyFy",
  },
  {
    id: 4,
    quote:
      "Ubinovalabs helped us start in the AI world, allowing us to study our customers better and provide tailored advice for each one.",
    avatar: Avatar4,
    name: "Michelle Hoeger",
    profession: "CEO, Health Foods Beat",
  },
  {
    id: 5,
    quote:
      "Ubinovalabs introduced AI in our business, helping us find more products and create better marketing strategies, increasing our sales by 35%.",
    avatar: Avatar5,
    name: "Francisco Torres",
    profession: "CEO, 8Letras",
  },
];

export default data