// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// import required modules
import { Pagination } from "swiper";

import testimonials from "./data";
import Testimonial from "./Testimonial";
import "./testimonials.css";
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const Testimonials = () => {
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    const activeTestimonial = testimonials[activeIndex];
    trackCustomEvent('Testimonial', 'View', `Testimonial ${activeTestimonial.id}`);
  };

  return (
    <section id="testimonials">
      <h2>What Our Clients Say</h2>
      <p>These are unbiased testimonials from some of my clients</p>
      <div className="container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            601: { slidesPerView: 2 },
            1025: { slidesPerView: 3 },
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={true}
          onSlideChange={handleSlideChange}  // Track slide changes
          className="mySwiper"
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Testimonial testimonial={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
