import React from 'react';
import { trackCustomEvent } from '../../analytics'; // Import the tracking function

const CategoryButton = ({ category, className, onChangeCategory }) => {

  const handleClick = () => {
    trackCustomEvent('Project Category Button', 'Click', category); // Track the category button click
    onChangeCategory(category);
  };

  return (
    <button className={className} onClick={handleClick}>
      {category}
    </button>
  );
}

export default CategoryButton;
