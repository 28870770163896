import Image1 from '../../assets/project1.jpg'
// import Image2 from '../../assets/project2.jpg'
// import Image3 from '../../assets/project3.jpg'
// import Image4 from '../../assets/project4.jpg'
// import Image5 from '../../assets/project5.jpg'
// import Image6 from '../../assets/project6.jpg'
// import Image7 from '../../assets/project7.jpg'
// import Image8 from '../../assets/project8.jpg'
// import Image9 from '../../assets/project9.jpg'

const data = [
  {
    id: 1,
    category: "python",
    image: Image1,
    title: "Pump5000 - Migration and System Modeling",
    desc: "Pump5000 is a migration project that involved upgrading from Pump4000 (Python 2.7, GTK2) to Pump5000 (Python 3.9, GTK3). It provides advanced pump and slurry system modeling using the DHLLDv Library, with modern UI enhancements and CI/CD integration.",
    demo: "http://yourprojectdemo.com",
    github: "https://github.com/yourgithub",
    private: true,
  },
  // {
  //   id: 2,
  //   category: "frontend",
  //   image: Image2,
  //   title: "Project Title Two (Frontend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 3,
  //   category: "frontend",
  //   image: Image3,
  //   title: "Project Title Three (Frontend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 4,
  //   category: "uiux",
  //   image: Image4,
  //   title: "Project Title Four (UIUX)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 5,
  //   category: "uiux",
  //   image: Image5,
  //   title: "Project Title Five (UIUX)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 6,
  //   category: "frontend",
  //   image: Image6,
  //   title: "Project Title Six (Frontend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 7,
  //   category: "frontend",
  //   image: Image7,
  //   title: "Project Title Seven (Frontend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 8,
  //   category: "backend",
  //   image: Image8,
  //   title: "Project Title Eight (Backend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 9,
  //   category: "frontend",
  //   image: Image9,
  //   title: "Project Title Nine (Frontend)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false,
  // },
  // {
  //   id: 10,
  //   category: "uiux",
  //   image: Image1,
  //   title: "Project Title One (UIUX)",
  //   desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
  //   demo: "http://egatortutorials.com",
  //   github: "https://github.com/egattor",
  //   private: false, 
  // },
];


export default data