import "./about.css";
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const About = () => {

  const handleSectionView = (section) => {
    trackCustomEvent('About Section', 'View', section);
  };

  return (
    <section id="about" className="about__section">
      <div className="about__content" data-aos="fade-up">
        <h3 className="about__heading">Ubinovalabs—Innovating for Impact</h3>
        <p>
          At Ubinovalabs in Buffalo, NY, and Lund, Sweden, we harness the power
          of AI and machine learning to revolutionize industries. As your
          strategic partner, we deliver targeted solutions that enhance
          performance and drive success.
        </p>
        <p>
          Let's shape the future of your business together with intelligence and
          innovation.
        </p>
        <h4 
          className="about__subheading" 
          onClick={() => handleSectionView('Our Vision')}
        >
          Our Vision
        </h4>
        <p>Envisioning a smarter future through advanced AI.</p>
        <h4 
          className="about__subheading" 
          onClick={() => handleSectionView('Our Mission')}
        >
          Our Mission
        </h4>
        <p>Empowering businesses with bespoke AI and data-driven insights.</p>
      </div>
    </section>
  );
};

export default About;
