// src/sections/header/Header.jsx

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './header.css';
import { trackCustomEvent } from '../../analytics';

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handleCTAClick = () => {
    trackCustomEvent('Button', 'Click', 'Let\'s talk');
  };

  return (
    <header id="header">
      <div className="container header__container" data-aos="fade-up">
        <h1 className="header__title">
          Pioneering AI and Machine Learning
          <br />
          Adopting Solutions
        </h1>
        <br />
        <p className="header__subtitle">
          Drive innovation with Ubinovalabs, where cutting-edge technology
          <br />
          meets business acumen
        </p>
        <div className="header__cta" data-aos="fade-up">
          <a href="#contact" className="btn primary" onClick={handleCTAClick}>
            Let's talk
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
