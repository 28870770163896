import React, { useState } from "react";
import "./study-cases.css";
import pic1 from "../../assets/winepouring.png";
import pic2 from "../../assets/vitalcare.png";
import pic3 from "../../assets/charging.png";
import pic4 from "../../assets/restaurant-icon.png";
import pic5 from "../../assets/heart-rate.png";
import pic6 from "../../assets/green-energy.png";
import Modal from "./Modal"; // Import the modal component
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const Studycase = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const studyCases = [
    {
      id: 1,
      title: "SmarTapon",
      description:
        "Ubinovalabs implemented a cloud and IoT-based solution for SmartTapon, recovering 30% of lost revenue and enhancing operational efficiency. Our team of eight specialists designed a scalable, impactful system that revolutionized their business operations.",
      img: pic1,
      img_overlay: pic4,
      details: (
        <div>
          <h3>Case Study: SmarTapon</h3>
          <img src={pic1} alt="SmarTapon" className="modal-image" />
          <p>
            <strong>Industry:</strong> SmartTapon operates in the [specify industry, e.g., retail, manufacturing, etc.], leveraging modern technology to enhance operational efficiency and profitability.
          </p>
          <p>
            <strong>Challenge:</strong> SmartTapon faced significant revenue leakage due to outdated systems and inefficient processes.
          </p>
          <p>
            <strong>Solution:</strong> Ubinovalabs developed a customized solution integrating cloud computing and IoT technologies. This smart system streamlined operations, enabled real-time data monitoring, and significantly improved decision-making capabilities.
          </p>
          <strong>Results:</strong>
          <ol className="results-list">
            <li>
              <strong>Revenue Recovery:</strong> Successfully recovered 30% of lost revenue within the first year of implementation.
            </li>
            <li>
              <strong>Operational Efficiency:</strong> Enhanced process optimization reduced downtime and maintenance costs.
            </li>
            <li>
              <strong>Scalability:</strong> The solution's cloud-based framework ensures easy scalability to meet growing business needs.
            </li>
          </ol>
          <strong>Technology Used:</strong>
          <ol className="tech-list">
            <li>
              <strong>Cloud Computing: </strong> Provided the backbone for scalable storage and data processing.
            </li>
            <li>
              <strong>IoT: </strong> Enabled seamless integration and communication between devices and systems.
            </li>
          </ol>
          <p>
            <strong>Team:</strong> A dedicated team of 8 experts from Ubinovalabs, including system architects, data scientists, and IoT specialists, collaborated closely with SmartTapon to ensure a successful implementation.
          </p>
        </div>
      ),
    },
    {
      id: 2,
      title: "Vital Care",
      description:
        "Ubinovalabs implemented a cloud and IoT-based solution for Vital Care, enhancing operational efficiency and providing significant cost savings. Our team of eight specialists designed a scalable, impactful system that revolutionized their business operations.",
      img: pic2,
      img_overlay: pic5,
      details: (
        <div>
          <h3>Case Study: Vital Care</h3>
          <img src={pic2} alt="Vital Care" className="modal-image" />
          <p>
            <strong>Industry:</strong> Vital Care operates in the personal emergency response system (PERS) industry, providing peace of mind through intelligent monitoring and emergency detection.
          </p>
          <p>
            <strong>Challenge:</strong> Vital Care faced challenges with operational inefficiencies and revenue loss due to outdated monitoring systems, impacting their ability to serve customers effectively.
          </p>
          <p>
            <strong>Solution:</strong> Ubinovalabs developed a cloud-based, IoT-integrated wearable system for Vital Care, offering real-time health monitoring and emergency response capabilities.
          </p>
          <strong>Results:</strong>
          <ol className="results-list">
            <li>
              <strong>Revenue Growth:</strong> Achieved a 20% increase in service subscriptions and expanded market reach.
            </li>
            <li>
              <strong>Operational Efficiency:</strong> Streamlined processes reduced response times by 40% and decreased maintenance costs.
            </li>
            <li>
              <strong>Customer Satisfaction:</strong> Improved customer satisfaction scores by 30% due to enhanced service reliability and responsiveness.
            </li>
          </ol>
          <strong>Technology Used:</strong>
          <ol className="tech-list">
            <li>
              <strong>Cloud Computing: </strong> Provided scalable storage and real-time data processing.
            </li>
            <li>
              <strong>IoT: </strong> Enabled continuous health monitoring and emergency detection through wearable devices.
            </li>
          </ol>
          <p>
            <strong>Team:</strong> A dedicated team of 8 experts from Ubinovalabs, including system architects, data scientists, and IoT specialists, collaborated closely with Vital Care to ensure a successful implementation.
          </p>
          <p>
            <strong>Client Feedback:</strong> "Ubinovalabs' solution significantly improved our operational efficiency and customer satisfaction. Their expertise in cloud and IoT technologies was instrumental in our business transformation."
          </p>
        </div>
      ),
    },
    {
      id: 3,
      title: "EnergyFy",
      description:
        "Ubinovalabs implemented a cloud and IoT-based solution for EnergyFy, recovering 25% of lost revenue and enhancing operational efficiency. Our team of eight specialists designed a scalable, impactful system that revolutionized their business operations.",
      img: pic3,
      img_overlay: pic6,
      details: (
        <div>
          <h3>Case Study: EnergyFy</h3>
          <img src={pic3} alt="EnergyFy" className="modal-image" />
          <p>
            <strong>Industry:</strong> EnergyFy operates in the energy management industry, leveraging artificial intelligence to optimize energy consumption and production for homes and small businesses.
          </p>
          <p>
            <strong>Challenge:</strong> EnergyFy faced challenges in accurately predicting energy consumption patterns and optimizing energy distribution, leading to inefficiencies and increased costs for users.
          </p>
          <p>
            <strong>Solution:</strong> Ubinovalabs developed a customized AI-powered platform for EnergyFy. This smart system integrated machine learning algorithms with real-time data analytics, providing users with actionable insights to manage their energy usage efficiently.
          </p>
          <strong>Results:</strong>
          <ol className="results-list">
            <li>
              <strong>Energy Savings:</strong> Users experienced an average reduction of 25% in energy bills within the first six months.
            </li>
            <li>
              <strong>Enhanced Efficiency:</strong> Improved prediction accuracy reduced energy wastage and optimized production.
            </li>
            <li>
              <strong>User Satisfaction:</strong> High user engagement and satisfaction due to intuitive and actionable insights provided by the platform.
            </li>
          </ol>
          <strong>Technology Used:</strong>
          <ol className="tech-list">
            <li>
              <strong>Artificial Intelligence: </strong> Enabled predictive analytics and machine learning for optimizing energy use.
            </li>
            <li>
              <strong>Real-Time Data Analytics: </strong> Provided instant feedback and actionable insights to users.
            </li>
          </ol>
          <p>
            <strong>Team:</strong> A dedicated team of 10 experts from Ubinovalabs, including AI researchers, data scientists, and software engineers, collaborated closely with Energyfy to ensure a successful implementation.
          </p>
        </div>
      ),
    },
  ];

  const handleOverlayClick = (caseDetails, caseTitle) => {
    trackCustomEvent('Study Case', 'View Details', caseTitle);  // Track modal open event
    setModalContent(caseDetails);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
  };

  return (
    <section id="studycases">
      <h2>Study Cases</h2>
      <div className="studycases__container">
        {studyCases.map((caseItem) => (
          <div className="studycase" key={caseItem.id} data-aos="fade-up">
            <img
              src={caseItem.img_overlay}
              alt={caseItem.title}
              className="studycase__img"
            />
            <div
              className="overlay"
              onClick={() => handleOverlayClick(caseItem.details, caseItem.title)}
            >
              <div className="overlay__content" data-aos="fade-up">
                <img
                  src={caseItem.img}
                  alt={caseItem.title}
                  className="overlay__img"
                />
                <p data-aos="fade-up">{caseItem.description}</p>
              </div>
            </div>
            <h4 className="studycase__title" data-aos="fade-up">
              {caseItem.title}
            </h4>
          </div>
        ))}
      </div>
      <Modal show={showModal} onClose={closeModal} content={modalContent} />
    </section>
  );
};

export default Studycase;

