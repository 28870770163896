import Logo from '../../assets/logo.jpg';
import data from './data';
import './navbar.css';
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const Navbar = () => {

  const handleNavLinkClick = (title) => {
    trackCustomEvent('Navigation', 'Click', title);
  };

  return (
    <nav>
      <div className="container nav__container">
        <a href="index.html" className='nav__logo'>
          <img src={Logo} alt="Logo" className="nav__logo-img"/>
        </a>
        <ul className='nav__menu'>
          {
            data.map(item => (
              <li key={item.id}>
                <a href={item.link} onClick={() => handleNavLinkClick(item.title)}>
                  {item.title}
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
