import { LuBrainCircuit } from "react-icons/lu";
import { SiCircuitverse } from "react-icons/si";
import { TbDeviceAnalytics } from "react-icons/tb";
import { PiNetworkFill } from "react-icons/pi";

const data = [
  {
    id: 1,
    icon: <LuBrainCircuit />,
    title: "Artificial Intelligence",
    desc: "Custom AI solutions to transform your data into actionable insights.",
  },
  {
    id: 2,
    icon: <SiCircuitverse />,
    title: "Machine Learning",
    desc: "Harnessing predictive power to optimize operations and increase efficiency.",
  },
  {
    id: 3,
    icon: <TbDeviceAnalytics />,
    title: "Data Analytics",
    desc: "In-depth analysis to uncover trends and drive decision-making.",
  },
  {
    id: 4,
    icon: <PiNetworkFill />,
    title: "IoT Solutions",
    desc: "Integrating connected devices to streamline workflows and enhance real-time decision-making.",
  },
];

export default data;
