import { FaWhatsapp } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { GoMail } from "react-icons/go";

const data = [
  { id: 1, icon: <FaWhatsapp />, link: "https://wa.me/+16077596219" },
  { id: 2, icon: <SiGmail />, link: "mailto:ubinovalabs@gmail.com" },
  { id: 3, icon: <GoMail />, link: "mailto:info@ubinovalabs.com" },
];

// alternative whatsApp link
// { id: 3, icon: <FaWhatsapp />, link: "https://wa.me/+233557097546" },
// https://wa.me/yournumber
// https://api.whatsapp.com/send/?phone=%2B233557097546

export default data;
