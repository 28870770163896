import { loadGA } from './analytics';
import Navbar from "./sections/navbar/Navbar";
import Header from "./sections/header/Header";
import About from "./sections/about/About";
import Services from "./sections/services/Services";
import StudyCases from "./sections/studycases/StudyCases";
import Testimonials from "./sections/testimonials/Testimonials";
import Portfolio from "./sections/portfolio/Portfolio";
import Contact from "./sections/contact/Contact";
import Footer from "./sections/footer/Footer";
import FloatingNav from "./sections/floating-nav/FloatingNav";

import { useRef, useState, useEffect, useCallback } from 'react';

const App = () => {
  const mainRef = useRef();
  const [showFloatingNav, setShowFloatingNav] = useState(true);
  const [siteYPostion, setSiteYPosition] = useState(0);

  const showFloatingNavHandler = useCallback(() => {
    setShowFloatingNav(true);
  }, []);

  const hideFloatingNavHandler = useCallback(() => {
    setShowFloatingNav(false);
  }, []);

  const floatingNavToggleHandler = useCallback(() => {
    if(siteYPostion < (mainRef?.current?.getBoundingClientRect().y - 20) || siteYPostion > (mainRef?.current?.getBoundingClientRect().y + 20)) {
      showFloatingNavHandler();
    } else {
      hideFloatingNavHandler();
    }

    setSiteYPosition(mainRef?.current?.getBoundingClientRect().y);
  }, [siteYPostion, showFloatingNavHandler, hideFloatingNavHandler]);

  useEffect(() => {
    // Load Google Analytics when the app starts
    loadGA();

    const checkYPosition = setInterval(floatingNavToggleHandler, 2000);

    // cleanup function
    return () => clearInterval(checkYPosition);
  }, [siteYPostion, floatingNavToggleHandler]);

  return (
    <main ref={mainRef}>
      <Navbar />
      <Header />
      <About />
      <Services />
      <StudyCases />
      <Testimonials />
      <Portfolio />
      <Contact />
      <Footer />
      {showFloatingNav && <FloatingNav />}
    </main>
  );
};

export default App;

