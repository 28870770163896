// src/analytics.js

// Function to dynamically load the Google Analytics script
export function loadGA() {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-B388PF5PTG`;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag("js", new Date());
    gtag("config", "G-B388PF5PTG");
  };
}

export function trackCustomEvent(eventCategory, eventAction, eventLabel) {
  if (window.gtag) {
    window.gtag("event", eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
}
