import './card.css';
import { trackCustomEvent } from '../analytics'; // Import the tracking function

const Card = ({ children, className, onClick }) => {

  const handleClick = (e) => {
    trackCustomEvent('Card', 'Click', className); // Track card click event
    if (onClick) {
      onClick(e); // Call the passed-in onClick handler if provided
    }
  };

  return (
    <article className={`card ${className}`} onClick={handleClick}>
        {children}
    </article>
  );
}

export default Card;
