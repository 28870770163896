import { trackCustomEvent } from '../../analytics'; // Import the tracking function

const Nav = ({ className, item }) => {

  const handleNavClick = () => {
    trackCustomEvent('Navigation Item', 'Click', item.link);
  };

  return (
    <li className={className}>
      <a href={item.link} onClick={handleNavClick}>
        {item.icon}
      </a>
    </li>
  );
}

export default Nav;
