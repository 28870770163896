const data = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About us" },
  { id: 3, link: "#services", title: "Our Services" },
  { id: 4, link: "#studycases", title: "Study Cases" },
  { id: 5, link: "#testimonials", title: "Testimonials" },
  { id: 6, link: "#portfolio", title: "Portfolio" },
  { id: 7, link: "#contact", title: "Contact us" },
];

export default data;
