import pic1 from "../../assets/heart-rate.png";
import pic2 from "../../assets/restaurant-icon.png";
import pic3 from "../../assets/trade.png";
import pic4 from "../../assets/green-energy.png";
import Card from "../../components/Card";
import data from "./data";
import "./services.css";
import { trackCustomEvent } from '../../analytics';  // Import the tracking function

const Services = () => {

  const handleServiceClick = (title) => {
    trackCustomEvent('Service', 'Click', title);
  };

  const handleIndustryClick = (industry) => {
    trackCustomEvent('Industry', 'View', industry);
  };

  return (
    <section id="services">
      <h2>Our Services</h2>
      <p>We give you the best in all the services below</p>
      <div className="container services__container" data-aos="fade-up">
        {data.map((item) => (
          <Card 
            key={item.id} 
            className="service light"
            onClick={() => handleServiceClick(item.title)}  // Track service clicks
          >
            <div className="service__icon">{item.icon}</div>
            <div className="service__details">
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </Card>
        ))}
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2>Industries Served</h2>
      <div className="industries__container">
        <div className="industry" data-aos="fade-up" onClick={() => handleIndustryClick('Health/Sports')}>
          <img src={pic1} alt="Health/Sports" className="industry__icon" />
          <h4>Health/Sports</h4>
        </div>
        <div className="industry" data-aos="fade-up" onClick={() => handleIndustryClick('Retails/Restaurant')}>
          <img src={pic2} alt="Retails/Restaurant" className="industry__icon" />
          <h4>Retails/Restaurant</h4>
        </div>
        <div className="industry" data-aos="fade-up" onClick={() => handleIndustryClick('Finance/Trading')}>
          <img src={pic3} alt="Finance/Trading" className="industry__icon" />
          <h4>Finance/Trading</h4>
        </div>
        <div className="industry" data-aos="fade-up" onClick={() => handleIndustryClick('Energy')}>
          <img src={pic4} alt="Energy" className="industry__icon" />
          <h4>Energy</h4>
        </div>
      </div>
    </section>
  );
};

export default Services;
